@import url('https://fonts.googleapis.com/css2?family=Cabin&display=swap');

.navbar {
    font-family: 'Cabin';
    margin-right: 25px;
    color: rgba(0, 0, 0, 1);
    border-bottom: 1px solid #C4C4C4;
}

.transparentbar {
    width: 0%;
}

.Navbar {
    padding-left: 0px;
    padding-right: 0px;
    background-color: white;

}

@media (max-width: 1000px) {
    .navbar {
        font-family: 'Cabin';
        margin-right: 25px;
        color: rgba(0, 0, 0, 1);
    }
}