@import url('https://fonts.googleapis.com/css2?family=Cabin&display=swap');

.inp {
    border:none;
    border-bottom: 1px solid #0a0b0b;
    outline: none;
    width: 95%;
 }

[placeholder]:focus::-webkit-input-placeholder {
    -webkit-transition: text-indent 0.4s 0.4s ease;
    transition: text-indent 0.4s 0.4s ease; 
    text-indent: -100%;
    opacity: 1;
 }

.ppcp {
    font-size: xx-small;
 }
 
.or {
    margin-left: 180px;
}

.errorMessage {
    text-align: center;
}

.form {
    padding-top: 30px;
}

.label {
    width: 100%;
}

@media (max-width: 1000px) {
    .inp {
        border:none;
        border-bottom: 1px solid #0a0b0b;
        outline: none;
     }
    
    [placeholder]:focus::-webkit-input-placeholder {
        -webkit-transition: text-indent 0.4s 0.4s ease;
        transition: text-indent 0.4s 0.4s ease; 
        text-indent: -100%;
        opacity: 1;
     }
}